<template>
  <div>
    <div>
      <KTPortlet
        :title="
          $tools.isNullOrUndefined(service)
            ? 'My SIM'
            : `Usage (+${service.service.service_reference})`
        "
        data-app="simcard"
      >
        <template v-slot:toolbar v-if="!$tools.isNullOrUndefined(service)">
          <img
            v-if="service.device.brand_name === service.device.phone_model"
            :src="service.device.brand_logo"
            style="height: 45px;"
          />
          <img v-else :src="service.device.phone_image" style="height: 45px;" />
        </template>
        <template slot="body">
          <Loader v-if="service === null" :inContent="true">Loading...</Loader>
          <template v-else>
            <apexcharts
              v-if="!this.$tools.isNullOrUndefined(this.usage)"
              width="100%"
              height="500px"
              type="area"
              :options="chartOptions"
              :series="chartData"
            ></apexcharts>
          </template>
        </template>
      </KTPortlet>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import KTPortlet from "@/views/partials/content/Portlet.vue";
import Loader from "@/views/partials/content/Loader.vue";
import { SET_BREADCRUMB } from "@/store/breadcrumbs.module";
//import Swal from "sweetalert2";
import TLib from "./lib";
import VueApexCharts from "vue-apexcharts";
import moment from "moment";
//import ApiService from "@/common/api.service";

export default {
  components: {
    KTPortlet,
    Loader,
    apexcharts: VueApexCharts
  },
  watch: {},
  data() {
    return {
      service: null,
      usage: null,
      chartOptions: {
        chart: {
          type: "area",
          height: 350
        },
        dataLabels: {
          enabled: false
        },
        stroke: {
          curve: "straight"
        },

        xaxis: {
          labels: {
            formatter: function(value, timestamp) {
              return moment(new Date(timestamp)).format("DD/MM/YYYY");
            }
          },
          type: "datetime",
          axisBorder: {
            show: false
          },
          axisTicks: {
            show: false
          }
        },
        yaxis: {
          tickAmount: 4,
          floating: false,

          labels: {
            formatter: function(value) {
              return (value * (value < 0 ? -1 : 1)).toFixed(2) + " GB";
            },
            style: {
              colors: "var(--primary)"
            },
            offsetY: -7,
            offsetX: 0
          },
          axisBorder: {
            show: false
          },
          axisTicks: {
            show: false
          }
        },
        fill: {
          opacity: 0.5
        },
        tooltip: {
          x: {
            format: "datetime"
          },
          fixed: {
            enabled: false,
            position: "topRight"
          }
        },
        grid: {
          yaxis: {
            lines: {
              offsetX: -30
            }
          },
          padding: {
            left: 20
          }
        }
      }
    };
  },
  methods: {
    updateService(serviceId) {
      let self = this;
      self.updateUsage(serviceId);
      self.$data.service = null;
      TLib.getSIM(this, serviceId)
        .then(x => {
          self.$nextTick(() => {
            self.$data.service = x;
          });
        })
        .catch(x => {
          self.$log.error(x);
          self.$log.error("Error loading content");
        });
    },
    updateUsage(serviceId) {
      let self = this;
      self.$data.usage = null;
      TLib.getUsage(this, serviceId)
        .then(x => {
          self.$nextTick(() => {
            self.$data.usage = x;
          });
        })
        .catch(x => {
          self.$log.error(x);
          self.$log.error("Error loading content");
        });
    }
  },
  computed: {
    ...mapGetters(["layoutConfig"]),

    config() {
      return this.layoutConfig();
    },

    chartData() {
      let data = [
        {
          name: "Peak",
          data: []
        },
        {
          name: "Off Peak",
          data: []
        },
        {
          name: "Total",
          data: []
        }
      ];

      if (this.$tools.isNullOrUndefined(this.usage)) return;

      for (let itemI of Object.keys(this.usage.daily_usage)) {
        let item = this.usage.daily_usage[itemI];
        if (typeof item !== typeof { a: "" }) continue;
        data[0].data.push({
          x: item.date,
          y: item.peak
        });
        data[1].data.push({
          x: item.date,
          y: -1 * item.offpeak
        });
        data[2].data.push({
          x: item.date,
          y: item.total
        });
      }

      return data;
    }
  },
  beforeDestroy() {
    this.$eventBus.off(`switch-service-selected`);
    //this.$eventBus.off(`cache-crm:services`);
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: "BetterMobile" },
      { title: "Manage SIM" }
    ]);

    this.updateService(this.$route.params.serviceId);

    let self = this;

    self.$eventBus.on(`switch-service-selected`, x => self.updateService(x));
    //self.$eventBus.on(`cache-crm:services`, () => self.resync(false));
    //self.resync();
  }
};
</script>
