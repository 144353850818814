import ApiService from "@/common/api.service";

//const gatewaysCacheKey = 'betternetworks:gateways';
//const devicesCacheKey = 'betternetworks:devices';
//let VM;
export default {
  initUpdator(/*self */) {
    //console.log('initUpdator')
    //let me = this;
    //VM = this;
    /*self.$eventBus.on( "ws-networks-device-update", async ( updateData ) => {
      await me.getDevices( self, null, true );
      return self.$eventBus.emit( 'networks-device-update', updateData );
    } );
    self.$eventBus.on( "ws-networks-templates-update", ( updateData ) => {
      //console.log('UPDATE TRIGGER: ' + updateData.id);
      me.getTemplates( self, null, true ).then( () => {} ).catch( () => {} );
      me.getTemplates( self, updateData.id, true ).then( () => {} ).catch( () => {} );
      if ( !self.$tools.isNullOrUndefined( updateData.draftVersion ) ) {
        //console.log('UPDATE TRIGGER: ' + `${updateData.id}.${updateData.draftVersion}`);
        me.getTemplates( self, `${updateData.id}.${updateData.draftVersion}`, true ).then( () => {} ).catch( () => {} );
      }
    } );
    self.$eventBus.on( "ws-networks-gateway-syslog-result", syslog => {
      let syslogList = [];
      let sysLogData = window.sessionStorage.getItem( 'syslogs' );
      if ( !self.$tools.isNullOrUndefined( sysLogData ) ) {
        syslogList = JSON.parse( sysLogData );
      }
      me.getDevices( self )
        .then( deviceList => {
          for ( let device of deviceList ) {
            if ( device.id !== syslog.deviceId ) continue;
            return self.$eventBus.emit( 'notification', {
              value: {
                title: syslog.result.message,
                color: syslog.result.message.indexOf( 'error' ) < 0 ? 'info' : 'danger',
                icon: 'flaticon-layer',
                postNote: device.name,
                link: `/Networks/Syslog/${syslog.gatewayId}-${syslog.deviceId}-${syslog.result.date}-${syslog.result.gatewayTime}`
              },
              time: new Date( syslog.result.date ).getTime(),
              type: 'events'
            } );
          }
        } );

      syslogList.push( syslog );

      if ( syslogList.length > 100 ) {
        syslogList.splice( 100 );
      }
      window.sessionStorage.setItem( 'syslogs', JSON.stringify( syslogList ) );

      self.$eventBus.emit( 'gateway-syslog', syslog );
    } );*/
  },
  getSIM: (self, serviceId, bumpCache = false) =>
    new Promise((resolve, reject) => {
      ApiService.getWithCache(
        self,
        `mobile`,
        `sim/${serviceId}`,
        `mobile-info-${serviceId}`,
        bumpCache
      )
        .then(resolve)
        .catch(reject);
    }),
  getUsage: (self, serviceId, bumpCache = false) =>
    new Promise((resolve, reject) => {
      ApiService.getWithCache(
        self,
        `mobile`,
        `sim/${serviceId}/usage`,
        `mobile-info-${serviceId}-usage`,
        bumpCache
      )
        .then(resolve)
        .catch(reject);
    })
  /*getTypes: ( self, bumpCache = false ) => new Promise( ( resolve, reject ) => {
    ApiService.getWithCache( self, "networks", "types", "betternetworks:types", bumpCache ).then( resolve ).catch( reject );
  } ),
  getTemplates: ( self, templateId, bumpCache = false ) => new Promise( ( resolve, reject ) => {
    ApiService.getWithCache( self, "networks", ( templateId !== undefined && templateId !== null ? `templates/${templateId}` : 'templates' ), `betternetworks:templates${(templateId !== undefined && templateId !== null ? `-${templateId}` : '')}`, bumpCache ).then( resolve ).catch( reject );
  } ),
  getDevices: ( self, deviceId, bumpCache = false ) => new Promise( ( resolve, reject ) => {
    ApiService.getWithCache( self, "networks", ( deviceId !== undefined && deviceId !== null ? `devices/${deviceId}` : 'devices' ), `${devicesCacheKey}${(deviceId !== undefined && deviceId !== null ? `-${deviceId}` : '')}`, bumpCache ).then( resolve ).catch( reject );
  } ),
  saveDevice: ( self, deviceId, config ) => new Promise( ( resolve, reject ) => {
    if ( self.$tools.isNullOrUndefined( deviceId ) ) return reject( 'NO ID' );
    ApiService.update( 'networks/devices', deviceId, config ).then( () => {
        VM.getDevices( self, null, true ).then( () => {
          resolve();
          //VM.getDevices( self, self.deviceId, true ).then( resolve ).catch( reject );
        } ).catch( reject );
      } )
      .catch( reject );
  } ),
  removeDevice: ( self, deviceId ) => new Promise( ( resolve, reject ) => {
    if ( self.$tools.isNullOrUndefined( deviceId ) ) return reject( 'NO ID' );
    ApiService.delete( `networks/devices/${deviceId}` ).then( () => {
        VM.getDevices( self, null, true ).then( () => {
          resolve();
          //VM.getDevices( self, self.deviceId, true ).then( resolve ).catch( reject );
        } ).catch( reject );
      } )
      .catch( reject );
  } ),
  getGateways: ( self, gatewayId, bumpCache = false ) => new Promise( ( resolve, reject ) => {
    ApiService.getWithCache( self, "networks", ( gatewayId !== undefined && gatewayId !== null ? `gateways/${gatewayId}` : 'gateways' ), `${gatewaysCacheKey}${(gatewayId !== undefined && gatewayId !== null ? `-${gatewayId}` : '')}`, bumpCache ).then( resolve ).catch( reject );
  } ),
  setTemplates: ( self, templateId, templateConfig, draftName, draftId ) => new Promise( ( resolve, reject ) => {
    ApiService.post( `networks/templates/${templateId}` + ( draftName !== undefined && draftName !== null ? `/${encodeURIComponent(draftName)}` : '' ) + ( draftId !== undefined && draftId !== null ? `?draftId=${draftId}` : '' ), {
        data: templateConfig
      } ).then( ( x ) => {
        VM.getTemplates( self ).then( async xtl => {
          for ( let template of xtl ) {
            if ( template.parentTemplateId === templateId ) {
              self.$cache.del( `betternetworks:templates-${template.id}` );
            }
          }
          resolve( x );
        } ).catch( reject )
      } )
      .catch( reject );
  } ),
  newTemplate: ( self, templateId, newTemplateName ) => new Promise( ( resolve, reject ) => {
    ApiService.post( `networks/templates/${templateId}`, {
        name: newTemplateName
      } ).then( ( newId ) => {
        self.$cache.del( `betternetworks:templates` );
        resolve( newId.data );
      } )
      .catch( reject );
  } ),
  deleteTemplates: ( self, templateId, draftId ) => new Promise( ( resolve, reject ) => {
    ApiService.delete( `networks/templates/${templateId}${draftId === undefined || draftId === null ? '' : `/${draftId}`}` ).then( () => {
        self.$cache.del( `betternetworks:templates` );
        self.$cache.del( `betternetworks:templates-${templateId}` );
        self.$cache.del( `betternetworks:templates-${templateId}.${draftId}` );
        resolve();
      } )
      .catch( reject );
  } ),
  deployDraft: ( self, templateId, draftId ) => new Promise( ( resolve, reject ) => {
    ApiService.put( `networks/templates/${templateId}/${draftId}` ).then( () => {
        VM.getTemplates( self, null, true ).then( () => {
          VM.getTemplates( self, templateId, true ).then( resolve ).catch( reject )
        } ).catch( reject );
      } )
      .catch( reject );
  } ),*/
};
